import { render, staticRenderFns } from "./AccountProfileForm.vue?vue&type=template&id=bfddfea0&"
import script from "./AccountProfileForm.vue?vue&type=script&lang=js&"
export * from "./AccountProfileForm.vue?vue&type=script&lang=js&"
import style0 from "./AccountProfileForm.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports