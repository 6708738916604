<template>
    <div class="nftcreate">
        <h2 data-focus>{{ $t('page.nftCreate.title') }}</h2>

        <nft-create-form />
    </div>
</template>

<script>
import NftCreateForm from '@/modules/nfts/components/NftCreateForm/NftCreateForm';
import { focusElem } from 'fantom-vue-components/src/utils/aria.js';

export default {
    name: 'NftCreate',

    components: { NftCreateForm },

    mounted() {
        focusElem(this.$el);
    },
};
</script>
