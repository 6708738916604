<template>
    <header id="appheader" class="appheader" :class="getMainNavClasses">
        <div class="appheader_logo">
            <router-link to="/" class="appheader_logo">
                <img src="/pixelwood-logo-black.svg" alt="PIXELWOOD" />
            </router-link>
        </div>
        <div class="main-nav">
            <app-menu />
        </div>
        <div class="appheader_col">
            <div class="appheader_mobile" :class="{ show: isShowMobileMenu }">
                <app-search-bar-with-whisperer />
            </div>
            <wallet-button-wrap />
            <app-hamburger class="appheader_burger" @switched="isShowMobileMenu = $event" />
        </div>
    </header>
</template>

<script>
import AppMenu from '@/modules/app/components/AppMenu/AppMenu.vue';
import WalletButtonWrap from '@/modules/wallet/components/WalletButtonWrap/WalletButtonWrap.vue';
import AppHamburger from '@/modules/app/components/AppHamburger/AppHamburger.vue';
import AppSearchBarWithWhisperer from '@/modules/app/components/AppSearchBarWithWhisperer/AppSearchBarWithWhisperer.vue';

export default {
    name: 'AppHeader',
    components: { AppSearchBarWithWhisperer, WalletButtonWrap, AppMenu, AppHamburger },
    data() {
        return {
            isShowMobileMenu: false,
        };
    },

    computed: {
        getMainNavClasses() {
            return {
                'scroll-up': this.scrollingUp,
                'scroll-down': this.scrollingDown,
                'appheader-home': this.$route.name === 'home',
            };
        },
    },
    methods: {
        scrollNow() {
            if (this.$route.name === 'home') {
                const appHeader = document.getElementById('appheader');
                const currentScrollPos = window.pageYOffset;

                if (currentScrollPos == 0) {
                    this.scrollingUp = false;
                    this.scrollingDown = false;
                    return;
                }

                if (currentScrollPos < 300) {
                    appHeader.classList.remove('sticky');
                    appHeader.classList.remove('sticky-ini');
                    return; // set offset here
                }

                if (this.prevScrollpos > currentScrollPos) {
                    // up
                    this.scrollingDown = false;
                    this.scrollingUp = true;
                } else {
                    // down
                    this.scrollingUp = false;
                    this.scrollingDown = true;
                }

                if (appHeader && this.scrollingDown) {
                    appHeader.classList.add('sticky');
                    setTimeout(() => appHeader.classList.add('sticky-ini'), 300);
                }

                this.prevScrollpos = currentScrollPos;
            }
        },

        handleScroll() {
            let doScoll;

            window.onscroll = () => {
                clearTimeout(doScoll);
                doScoll = setTimeout(this.scrollNow, 1); // firing less scroll events
            };
        },
    },

    created() {
        this.handleScroll();
    },
};
</script>

<style lang="scss">
@use "style";
</style>
