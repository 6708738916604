var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nftitemactivity"},[_c('NftItemActivityFilter',{model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}}),_c('NftItemActivityFilterChips',{directives:[{name:"show",rawName:"v-show",value:(_vm.filters.length > 0),expression:"filters.length > 0"}],model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}}),_c('f-data-grid',{ref:"grid",staticClass:"agrid",attrs:{"infinite-scroll":"","infinite-scroll-root":".nfttmpgrid .fdatagrid_table","strategy":"remote","no-header":"","max-height":"400px","sticky-head":"","items":_vm.items,"columns":_vm.itemsColumns,"total-items":_vm.totalItems,"per-page":_vm.perPage},on:{"change":_vm._onGridPageChange},scopedSlots:_vm._u([{key:"column-unitPrice",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('a-token-value',{attrs:{"token":item.payToken,"value":value,"fraction-digits":2,"use-placeholder":false,"no-symbol":""}})]}},{key:"column-fromUser",fn:function(ref){
var value = ref.value;
return [_c('router-link',{attrs:{"to":{ name: 'account', params: { address: value.address } }}},[_c('a-address',{attrs:{"address":value.address,"name":value.username,"image":value.avatarThumb,"is-account":""}})],1)]}},{key:"column-toUser",fn:function(ref){
var value = ref.value;
return [(value !== null)?_c('router-link',{attrs:{"to":{ name: 'account', params: { address: value.address } }}},[_c('a-address',{attrs:{"address":value.address,"name":value.username,"image":value.avatarThumb,"is-account":""}})],1):_vm._e()]}},{key:"column-time",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('div',[_c('a',{attrs:{"href":'https://ftmscan.com/tx/' + item.trxHash,"title":_vm.$t('nfthistorygrid.showTx'),"target":"_blank"}},[_vm._v(" "+_vm._s(value)+" ")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }