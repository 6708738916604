<template>
    <div id="footer">
        <div class="container">
            <div class="footer-content">
                <div class="footer-logo-main">
                    <a class="footer-logo" href="/">
                        <img src="/pixelwood-logo-white.svg" alt="PIXELWOODS">
                    </a>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                </div>
                <div class="footer-menu">
                    <h3>Marketplace</h3>
                    <ul class="footer-menu-list">
                        <li><a class="n-link-0" href="/explore?category=0">Art</a></li>
                        <li><a class="n-link-1" href="/explore?category=1">Collectibles</a></li>
                        <li><a class="n-link-2" href="/explore?category=2">Domain Names</a></li>
                        <li><a class="n-link-2" href="/explore?category=3">Sports</a></li>
                    </ul>
                </div>
                <div class="footer-menu">
                    <h3>Resources</h3>
                    <ul class="footer-menu-list">
                        <li><a class="n-link-5" href="#">Help Center</a></li>
                        <li><a class="n-link-6" href="#">Assortiment</a></li>
                    </ul>
                </div>
                <div class="footer-menu">
                    <h3>Socials</h3>
                    <ul class="footer-menu-list">
                        <li><a class="n-link-5" href="#">About</a></li>
                        <li><a class="n-link-5" href="#">Careers</a></li>
                    </ul>
                </div>
            </div>
            <div id="footer-bottom">
                <div class="footer-copyright">
                    © Copyright 2021 Pixelwoods
                </div>
                <ul class="footer-social">
                    <li>
                        <a href="https://discord.com/" target="_blank" title="Discord">
                            <i class="fab fa-discord"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/" target="_blank" title="Twitter">
                            <i class="fab fa-twitter"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppFooter',
};
</script>

<style lang="scss">
@use "style";
</style>
