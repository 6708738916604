<template>
    <nav class="appmenu flex" :aria-label="$t('mainNav')">
        <router-link v-for="(item, index) in navigation" :key="`am_${index}`" :to="{ name: item.route }">
            {{ item.label }}
        </router-link>
    </nav>
</template>

<script>
export default {
    name: 'AppMenu',

    data() {
        return {
            navigation: [
                {
                    route: 'explore',
                    label: this.$t('navigation.explore'),
                },
                // {
                //     route: 'collections',
                //     label: this.$t('navigation.collections'),
                // },
                {
                    route: 'nft-create',
                    label: this.$t('navigation.create'),
                },
                /*{
                    route: 'pg',
                    label: 'PG',
                },*/
            ],
        };
    },
};
</script>
