<template>
    <div class="density">
        <f-button
            :aria-label="$t('densityswitch.largeDisplay')"
            :data-tooltip="$t('densityswitch.largeDisplay')"
            data-density="large"
            @click.native="onDensitySwitchClick"
        >
            <i class="fa-solid fa-grid-2"></i>
        </f-button>
        <f-button
            :aria-label="$t('densityswitch.smallDisplay')"
            :data-tooltip="$t('densityswitch.smallDisplay')"
            data-density="small"
            @click.native="onDensitySwitchClick"
        >
            <i class="fa-light fa-grid"></i>
        </f-button>
    </div>
</template>
<script>
import { SET_NFTS_DENSITY } from '@/modules/app/store/mutations.js';

export default {
    name: 'DensitySwitch',

    components: { },

    methods: {
        onDensitySwitchClick(e) {
            const density = e.currentTarget.getAttribute('data-density');

            this.$store.commit(`app/${SET_NFTS_DENSITY}`, density);
            this.$emit('density-switch', density);
        },
    },
};
</script>
<style lang="scss">
@use 'style';
</style>
