<template>
    <section class="notificationsettings" :aria-labelledby="id">
        <div class="notificationsettings_title">
            <h1 :id="id" data-focus>{{ $t('notificationsettings.title') }}</h1>
            <p>{{ $t('notificationsettings.description') }}</p>
        </div>
        <notification-settings-form />
    </section>
</template>

<script>
import NotificationSettingsForm from '@/modules/account/components/NotificationSettingsForm/NotificationSettingsForm.vue';
import { focusElem } from 'fantom-vue-components/src/utils/aria.js';
import { getUniqueId } from 'fantom-vue-components/src/utils';

export default {
    name: 'NotificationSettings',

    components: { NotificationSettingsForm },

    data() {
        return {
            id: getUniqueId(),
        };
    },

    mounted() {
        focusElem(this.$el);
    },
};
</script>

<style>
#app,
.main,
:root {
    width: 100%;
    height: 100%;
}

.notificationsettings_title h1 {
    margin-top: 0;
}
.notificationsettings_title p {
    margin-top: 8px;
    margin-bottom: 32px;
}
</style>
