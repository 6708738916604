var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accountactivitylist"},[_c('f-data-grid',{ref:"grid",staticClass:"agrid nfttmpgrid",attrs:{"infinite-scroll":"","strategy":"remote","no-header":"","sticky-head":"","__max-height":"400px","__infinite-scroll-root":".nfttmpgrid .fdatagrid_table","caption":_vm.$t('page.accountActivity.title'),"items":_vm.items,"columns":_vm.columns,"total-items":_vm.totalItems,"per-page":_vm.perPage},on:{"change":_vm._onGridPageChange},scopedSlots:_vm._u([{key:"column-token",fn:function(ref){
var item = ref.item;
return [(item.token)?_c('router-link',{attrs:{"to":{ name: 'nft-detail', params: { tokenContract: item.contract, tokenId: item.tokenId } }}},[_c('a-address',{attrs:{"address":item.token.contract,"name":item.token.name,"image":item.token.imageThumb}})],1):_vm._e()]}},{key:"column-unitPrice",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('a-token-value',{attrs:{"token":item.payToken,"value":value,"fraction-digits":2,"use-placeholder":false,"no-symbol":""}})]}},{key:"column-quantity",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.transformQty(value))+" ")]}},{key:"column-fromUser",fn:function(ref){
var value = ref.value;
return [_c('router-link',{attrs:{"to":{ name: 'account', params: { address: value.address } }}},[_c('a-address',{attrs:{"address":value.address,"name":value.username,"image":value.avatarThumb,"is-account":""}})],1)]}},{key:"column-toUser",fn:function(ref){
var value = ref.value;
return [(value !== null)?_c('router-link',{attrs:{"to":{ name: 'account', params: { address: value.address } }}},[_c('a-address',{attrs:{"address":value.address,"name":value.username,"image":value.avatarThumb,"is-account":""}})],1):_vm._e()]}},{key:"column-time",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }