var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nftdirectoffersgrid"},[_c('f-data-grid',{ref:"grid",staticClass:"agrid nfttmpgrid",attrs:{"infinite-scroll":"","strategy":"remote","no-header":"","max-height":"400px","sticky-head":"","infinite-scroll-root":".nfttmpgrid .fdatagrid_table","caption":_vm.$t('nftdetail.directOffers'),"items":_vm.items,"columns":_vm.columns,"total-items":_vm.totalItems,"per-page":_vm.perPage},on:{"change":_vm._onGridPageChange},scopedSlots:_vm._u([{key:"column-proposedByUser",fn:function(ref){
var value = ref.value;
return [_c('router-link',{attrs:{"to":{ name: 'account', params: { address: value.address } }}},[_c('a-address',{attrs:{"address":value.address,"name":value.username,"image":value.avatarThumb,"is-account":""}})],1)]}},{key:"column-unitPrice",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('a-token-value',{attrs:{"token":item.payToken,"value":value,"fraction-digits":2,"use-placeholder":false,"no-symbol":""}})]}},{key:"column-actions",fn:function(ref){
var item = ref.item;
return [(_vm.actionExists(item))?[(_vm.canAccept(item))?_c('a-button',{attrs:{"loading":_vm.txStatus === 'pending' && _vm.pickedAddress === item.proposedBy,"label":_vm.$t('nftDirectOffersGrid.accept'),"disabled":_vm.tokenHasAuction,"data-tooltip":_vm.tokenHasAuction ? _vm.$t('nftDirectOffersGrid.cantAccept') : null},nativeOn:{"click":function($event){return _vm.onAcceptButtonClick(item)}}}):(_vm.canWithdraw(item))?_c('a-button',{attrs:{"loading":_vm.txStatus === 'pending' && _vm.pickedAddress === item.proposedBy,"label":_vm.$t('nftDirectOffersGrid.withdraw')},nativeOn:{"click":function($event){return _vm.onWithdrawButtonClick(item)}}}):_vm._e()]:_vm._e()]}}])}),_c('a-sign-transaction',{attrs:{"tx":_vm.tx},on:{"transaction-status":_vm.onTransactionStatus}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }