var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nftlistingsgrid"},[_c('f-data-grid',{ref:"grid",staticClass:"agrid nfttmpgrid",attrs:{"infinite-scroll":"","strategy":"remote","no-header":"","max-height":"400px","sticky-head":"","infinite-scroll-root":".nfttmpgrid .fdatagrid_table","caption":_vm.$t('nftdetail.listings'),"items":_vm.items,"columns":_vm.columns,"total-items":_vm.totalItems,"per-page":_vm.perPage},on:{"change":_vm._onGridPageChange},scopedSlots:_vm._u([{key:"column-ownerUser",fn:function(ref){
var value = ref.value;
return [_c('router-link',{attrs:{"to":{ name: 'account', params: { address: value.address } }}},[_c('a-address',{attrs:{"address":value.address,"name":value.username,"image":value.avatarThumb,"is-account":""}})],1)]}},{key:"column-unitPrice",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('a-token-value',{attrs:{"token":item.payToken,"value":value,"fraction-digits":2,"use-placeholder":false,"no-symbol":""}})]}},{key:"column-actions",fn:function(ref){
var item = ref.item;
return [(!item.closed && item.isActive)?[(!_vm.userOwnsToken && _vm.listingStarted(item))?_c('a-button',{attrs:{"loading":_vm.txStatus === 'pending',"label":_vm.$t('nftlistingsgrid.buy')},nativeOn:{"click":function($event){return _vm.onBuyButtonClick(item)}}}):(!_vm.listingStarted(item))?[_vm._v(" "+_vm._s(_vm.listingStartsAt(item))+" ")]:_vm._e()]:_vm._e()]}}])}),_c('a-sign-transaction',{attrs:{"tx":_vm.tx},on:{"transaction-status":_vm.onTransactionStatus}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }