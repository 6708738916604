<template>
    <div class="home">
        <app-section-nft :nft="nft" />

        <div class="home_shadow">
            <app-section-about />
            <!-- <app-section-category /> -->
        </div>
        <h2 class="home_title">
            Consequat sint proident ex dolore occaecat do aute Lorem
        </h2>
        <div id="content-0" class="content-banner-wrap animate">
            <div class="container">
                <div class="content-banner content-banner-txt-img">
                    <div class="banner-blk-txt">
                        <div class="content-banner-title">
                            <h2>Lorem ipsum dolor sit consectetur adipiscing elit</h2>
                        </div>
                        <div class="content-banner-txt">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Tellus mauris a diam maecenas sed enim ut
                                sem.
                            </p>
                            <button target="_blank" class="button">
                                <span>Button CTA</span>
                            </button>
                        </div>
                    </div>
                    <div class="banner-blk-img">
                        <img src="img/tmp/pixelwood-blue-bg.png" alt="Blue BG" />
                    </div>
                </div>
            </div>
        </div>
        <div id="content-1" class="content-banner-wrap animate">
            <div class="container">
                <div class="content-banner content-banner-txt-img">
                    <div class="banner-blk-img">
                        <img src="img/tmp/pixelwood-red-bg.png" alt="Blue BG" />
                    </div>
                    <div class="banner-blk-txt">
                        <div class="content-banner-title">
                            <h2>Ed do eiusmod tempor ut asd famet dolore magna</h2>
                        </div>
                        <div class="content-banner-txt">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Tellus mauris a diam maecenas sed enim ut
                                sem.
                            </p>
                            <button target="_blank" class="button">
                                <span>Button CTA</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sticky-top-footer">
            <div class="sticky-top-footer-wrap">
                <div class="sticky-top-footer-item">
                    <div class="small-icon-wrap">
                        <i class="fa-light fa-wallet"></i>
                    </div>
                    <span>Et malesuada fames ac turpis</span>
                </div>
                <div class="sticky-top-footer-item">
                    <div class="small-icon-wrap">
                        <i class="fa-light fa-bolt"></i>
                    </div>
                    <span>Et malesuada fames ac turpis</span>
                </div>
                <div class="sticky-top-footer-item">
                    <div class="small-icon-wrap">
                        <i class="fa-light fa-chart-line-down"></i>
                    </div>
                    <span>Et malesuada fames ac turpis</span>
                </div>
                <div class="sticky-top-footer-item">
                    <div class="small-icon-wrap">
                        <i class="fa-light fa-tags"></i>
                    </div>
                    <span>Et malesuada fames ac turpis</span>
                </div>
            </div>
        </div>
        <app-footer />
    </div>
</template>

<script>
import AppSectionNft from '@/modules/app/components/AppSectionNft/AppSectionNft.vue';
import AppSectionAbout from '@/modules/app/components/AppSectionAbout/AppSectionAbout.vue';
// import AppSectionCategory from '@/modules/app/components/AppSectionCategories/AppSectionCategories.vue';
import AppFooter from '@/modules/app/components/AppFooter/AppFooter.vue';

export default {
    name: 'Home',

    components: { AppFooter, AppSectionAbout, AppSectionNft },

    data() {
        return {
            nft: {},
        };
    },

    mounted() {
        // TMP
        this.nft = {
            title: 'Deserunt da nis',
            collection: 'Aute do nisi deserunt',
            img: 'img/tmp/pixelwood-mainbanner-img.png',
        };
    },
};
</script>

<style lang="scss">
@use "style";
</style>
