var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accountcollectionsgrid"},[_c('f-data-grid',{ref:"grid",staticClass:"agrid nfttmpgrid",attrs:{"infinite-scroll":"","strategy":"remote","sticky-head":"","__max-height":"400px","__infinite-scroll-root":".nfttmpgrid .fdatagrid_table","caption":_vm.$t('page.accountCollections.title'),"items":_vm.items,"columns":_vm.columns,"total-items":_vm.totalItems,"per-page":_vm.perPage},on:{"change":_vm._onGridPageChange},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"accountcollectionsgrid_header"},[_c('f-combo-box',{staticClass:"accountactivityfilter",attrs:{"data":_vm.filterData,"select-mode":"","readonly":"","inline-autocomplete":false,"field-size":"large","hide-on-document-scroll":"","fit-height-to-viewport":"","no-label":"","aria-label":_vm.$t('accountcollectionsgrid.filter')},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('f-search-field',{attrs:{"throttle-input-interval":400,"field-size":"large","placeholder":_vm.$t('accountcollectionsgrid.search'),"no-label":"","aria-label":_vm.$t('accountcollectionsgrid.search')},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)]},proxy:true},{key:"column-name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"accountcollectionsgrid_item",attrs:{"to":{ name: 'explore', query: { collections: item.contract } }}},[_c('f-image',{attrs:{"size":"70px","src":_vm.getCollectionImageUrl(item.contract),"alt":item.name,"aria-hidden":"true"}}),_vm._v(" "+_vm._s(item.name)+" ")],1)]}},{key:"column-description",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(value)+" "),_c('social',{attrs:{"info":item}})]}},{key:"column-ownerUser",fn:function(ref){
var value = ref.value;
return [_c('router-link',{attrs:{"to":{ name: 'account', params: { address: value.address } }}},[_c('a-address',{attrs:{"address":value.address,"name":value.username,"image":value.avatarThumb,"is-account":""}})],1)]}},{key:"column-contract",fn:function(ref){
var value = ref.value;
return [_c('a',{attrs:{"href":("https://ftmscan.com/address/" + value),"target":"_blank"}},[_c('f-ellipsis',{attrs:{"text":value,"overflow":"middle"}})],1)]}},{key:"column-royalty",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+"% ")]}},{key:"column-feeRecipientUser",fn:function(ref){
var value = ref.value;
return [_c('router-link',{attrs:{"to":{ name: 'account', params: { address: value.address } }}},[_c('a-address',{attrs:{"address":value.address,"name":value.username,"image":value.avatarThumb,"is-account":""}})],1)]}},{key:"column-actions",fn:function(ref){
var item = ref.item;
return [(_vm.filter === 'enabled')?_c('a-button',{attrs:{"loading":item._loading,"disabled":item._disabled},nativeOn:{"click":function($event){return _vm.onBanButtonClick(item)}}},[_c('app-iconset',{attrs:{"icon":"ban","size":"16px"}}),_vm._v(" "+_vm._s(_vm.$t('accountcollectionsgrid.ban.button'))+" ")],1):_vm._e(),(_vm.filter === 'banned')?_c('a-button',{attrs:{"loading":item._loading,"disabled":item._disabled},nativeOn:{"click":function($event){return _vm.onUnbanButtonClick(item)}}},[_c('app-iconset',{attrs:{"icon":"thumb-up","size":"16px"}}),_vm._v(" "+_vm._s(_vm.$t('accountcollectionsgrid.unban.button'))+" ")],1):(_vm.filter === 'inReview')?[_c('a-button',{attrs:{"loading":item._loading,"disabled":item._disabled},nativeOn:{"click":function($event){return _vm.onApproveButtonClick(item)}}},[_c('app-iconset',{attrs:{"icon":"thumb-up","size":"16px"}}),_vm._v(" "+_vm._s(_vm.$t('accountcollectionsgrid.approve.button'))+" ")],1),_c('a-button',{attrs:{"loading":item._loading,"disabled":item._disabled},nativeOn:{"click":function($event){return _vm.onDeclineButtonClick(item)}}},[_c('app-iconset',{attrs:{"icon":"thumb-down","size":"16px"}}),_vm._v(" "+_vm._s(_vm.$t('accountcollectionsgrid.decline.button'))+" ")],1)]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }